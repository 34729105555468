require('@ticketline/ticketline-seat-widget')
import { Widget } from '@ticketline/ticketline-seat-widget'

/* eslint no-console:0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './initializers'
import './pages'

// Turbo
// import * as Turbo from '@hotwired/turbo'
import '@hotwired/turbo'

// Stimulus
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
