$(document).on('turbo:load', () => {
  if ($('#events-show').length !== 0) {
    // Scroll to location dropdowns when banner buy button is clicked
    $('.scroll-buy').on('click', function () {
      $('html, body').animate({ scrollTop: $('#section-buy').offset().top - 150 }, 1500)
    })

    /* SYNOPSIS */
    /*if (document.getElementById('synopsis')) {
      const toggleEL = document.querySelector("[selector='synopsis_toggle']")
      const synopsisParent = document.getElementById('synopsis')
      const synopsisContainer = document.getElementById('synopsis-container')
      const synopsisTextEl = document.getElementById('synopsis-text')
      const fontSize = parseInt(window.getComputedStyle(synopsisTextEl).fontSize)

      if (toggleEL && (synopsisTextEl.offsetHeight-synopsisContainer.offsetHeight+(fontSize)) <= 0) {
        toggleEL.parentNode.removeChild(toggleEL)
        $('.synopsis__bg').hide()
      }

      let show = false
      $(toggleEL).on('click', function (e) {
        show = !show
        if (show) {
          synopsisParent.classList.add('synopsis-open')
        } else {
          synopsisParent.classList.remove('synopsis-open')
        }
      })
    }*/

    /* ADDITIONAL INFO */
    if (document.getElementById('synopsis')) {
      viewMoreControls('synopsis')
    }

    /* ADDITIONAL INFO */
    if (document.getElementById('additional_info')) {
      viewMoreControls('additional_info')
    }

    /* T&C INFO */
    if (document.getElementById('terms_and_conditions')) {
      viewMoreControls('terms_and_conditions')
    }

    /* ENTRY RULES INFO */
    if (document.getElementById('entry_rules')) {
      viewMoreControls('entry_rules')
    }

    /* MULTIMEDIA */
    if (document.getElementById('multimedia')) {
      $('.multimedia__card').on('click', ev => selectImage(ev.target))
      $('.multimedia__card')[0]?.click()
      document.querySelectorAll('[data-type="vimeo"]').forEach(item => {
        $.get(`https://vimeo.com/api/v2/video/${item.dataset.srcId}.json`)
          .then(data => (item.style.backgroundImage = `url('${data[0].thumbnail_small}')`))
      })
    }
  }

  $('.multimedia__card').on('click', function () {
    var $this = $(this)
    $this.siblings().removeClass('selected')
    $this.addClass('selected')
  })

  function selectImage (imgEl) {
    const expandImgEl = document.getElementById('container-multimedia')
    const divEl = document.createElement('div')
    divEl.className = 'multimedia__inner'
    expandImgEl.innerHTML = ''
    expandImgEl.appendChild(divEl)

    if (imgEl.dataset.type === 'youtube' || imgEl.dataset.type === 'vimeo') {
      divEl.innerHTML = `<div class="video" data-plyr-provider="${imgEl.dataset.type}" data-plyr-embed-id="${imgEl.dataset.srcId}"></div>`
      new Plyr(divEl.firstElementChild, { autoplay: false, muted: true, controls: ['play', 'mute', 'volume'] })
      divEl.firstElementChild.classList.add("video");
    } else {
      divEl.innerHTML = `<img src="${imgEl.dataset.srcId}" />`
    }
  }

  function viewMoreControls(id)  {
    const selectorId = `${id}_toggle`
    const toggleEL = document.querySelector(`[selector=${selectorId}]`)
    const parent = document.getElementById(id)
    const content = document.getElementById(`${id}-content`)

    if (toggleEL && content.offsetHeight <= 140) {
      toggleEL.parentNode.removeChild(toggleEL);
    } else {
      content.classList.add('descriptions-with-viewmore__area');
    }

    let show = false
    $(toggleEL).on('click', function (e) {
      show = !show
      if (show) {
        parent.classList.add('descriptions-with-viewmore-open')
      } else {
        parent.classList.remove('descriptions-with-viewmore-open')
        parent.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'center',
        })
      }
    })
  }
})
