import * as Routes from 'routes.js.erb';
require('@ticketline/ticketline-seat-widget');
import Widget from '@ticketline/ticketline-seat-widget';

let widget;
let $pageSpinner;
let $widgetLayout;
let $goToCartLink;

$(document).on('turbo:load', () => {
    $pageSpinner = $("#page_spinner");
    $widgetLayout = $("#layout_widget");
    $goToCartLink = $('.add_to_cart_button');

    if (!$('#events-event-session').length || !$widgetLayout.length) return;
    initializeWidget();
});

function initializeWidget() {
    $widgetLayout.empty();
    widget = new Widget(sessionData().layoutId, widgetSettings(), "", sessionData());
    widget.render($widgetLayout);
}

const sessionData = () => ({
    event_id: $('#event_id').val(),
    location_id: $('#location_id').val(),
    date: $('#date').val(),
    session: $('#time').val(),
    cart_id: $('#cart_id').val(),
    proxy_token: $('#session_id').val(),
    layoutId: $('#layout_id').val(),
    host: $('#host').val()
});

const widgetSettings = () => ({
    baseURL: $('#host').val().concat('/api/widget'),
    showSelectedView: false,
    showListView: true,
    showColorLegend: true,
    groupClickBehaviour: "expandAll",
    highlightSelectedGroups: false,
    disableZoom: false,
    minimap: false,
    tooltip: true,
    onChangeSelectionTimeoutMS: 500,
    onChangeSelection: (selected) => handleWidgetSelection(selected),
    onClickSeat: (seat) => { },
    onClickGroup: (group) => { },
    onHoverSeat: (seat) => { },
    onHoverGroup: (group) => { },
    onDoubleClickGroup: (group) => { },
    widgetInitialized: (log) => { },
    onClickAdmissionTicket: (info) => { },
});

function handleWidgetSelection(selected) {
    $("#page_spinner").show();
    $goToCartLink.addClass('disabled');
    sendSelectionToAPI(selected);
}

function updateCartCounter(totalTickets) {
    const widgetTotals = widget.getTotals();
    $('#total_ticket_count').text(widgetTotals.quantity);
    $('#total_ticket_price').text(widgetTotals.amount);
    if (totalTickets === 0) {
        $('.cart_icon__counter').remove();
    } else {
        $('.cart_icon__container').html(`<div class="cart_icon__counter">${totalTickets}</div><i class="fal fa-shopping-cart"></i>`);
    }
    $pageSpinner.hide();
}

function sendSelectionToAPI(selected) {
    const data = {
        event_id: $('#event_id').val(),
        location_id: $('#location_id').val(),
        date: $('#date').val(),
        session: $('#time').val(),
        seats: selected.seats.map(seat => ({ id: +seat.id, zoneId: +seat.parentId })),
        zones: selected.groups.map(group => ({ id: +group.id, quantity: +group.num }))
    };

    $.ajax({
        type: 'POST',
        url: Routes.api_widget_add_to_cart_path(),
        data: data,
        success: (response) => {
            widget.blockSeats(response.occupied_seats)
            if (widget.getTotals().quantity > 0) {
                $goToCartLink.removeClass('disabled');
            }
            updateCartCounter(response.tickets);
        },
        error: (response) => console.error(response)
    });
}
