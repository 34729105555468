import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  hide () {
    const bodyId = document.body.id;
    if (bodyId === 'cart-index' || bodyId === 'purchases-data' || bodyId === 'purchases-data-processing' ||
        bodyId === 'events-event-session' || bodyId === 'purchases-payment') {
      window.location.href = 'https://ticketline.pt'
      return
    }
    this.element.classList.add('none')
  }
}
