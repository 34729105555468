import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["ticketCounter", "expiresAt"]

  connect() {
    this.updateCartCounter()
  }

  // TODO: finish this when widget gets to stimulus
  updateCartCounter() {
    const totalTickets = parseInt(this.ticketCounterTarget.textContent, 10) || 0;
    if (totalTickets === 0) {
      //this.ticketCounterTarget.remove()
    } else {
      //this.ticketCounterTarget.innerText = totalTickets
    }
  }

}
