import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ["tooltip"]

  initializePopper(element, tooltip) {
    return createPopper(element, tooltip, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]
    })
  }

  show() {
    if (this.tooltipTarget.dataset.active !== "false"){
      const popper = this.initializePopper(this.element, this.tooltipTarget)
      this.tooltipTarget.setAttribute('data-show', '')
      
      popper.setOptions({
        modifiers: [{ name: 'eventListeners', enabled: true }]
      })
      
      popper.update()
    }
  }
  
  hide() {
    const popper = this.initializePopper(this.element, this.tooltipTarget)
    this.tooltipTarget.removeAttribute('data-show')

    popper.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: false }]
    })
  }
}
