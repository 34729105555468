import './cart_widget'

$(document).on('turbo:load', () => {
  $("#page_spinner").hide();

  if ($('#events-event-session').length !== 0) {
    const sessionMaxTickets = $(document).find('.session_max_tickets').val()
    const $addToCartButton = $('.event_session_bottom__submit')
    let block = false
    const layoutId = new URLSearchParams(window.location.search).get('layout_id')
    const calculateTotals = (precart = {}) => {
      let totalTickets = 0
      let totalPrice = 0
      if (layoutId) {
        precart.groups.map(group => {
          totalTickets += group.num
          totalPrice += group.num * parseFloat(group.price)
        })
        totalTickets += precart.seats.length
        totalPrice += precart.seats.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.price)
        }, 0)
      } else {
        totalTickets = $('.ticket').length
        $('.ticket').each(a => totalPrice + 10)

        $('.ticket_count').each(function () {
          const ticketCount = parseFloat($(this).text())
          if (sessionMaxTickets) {
            // the == needs to be 2 = not 3 because is checking if number match the number in string
            if ((totalTickets + ticketCount) == (sessionMaxTickets)) {
              block = true
              $(document).find('.increment').removeClass('bg-dark-gray').addClass('bg-light-gray')
              $(document).find('.tooltip').attr('data-active', 'true')
            } else if ((totalTickets + ticketCount) > (sessionMaxTickets)) {
              return
            }
          }
          const ticketPrice = $(this).parents('.render_typology').find('.ticket_price').text()
          totalTickets += ticketCount
          totalPrice += parseFloat(ticketPrice.replace(',', '.')).toFixed(2) * ticketCount
        })
      }
      $('.total_tickets').text(totalTickets)
      $('.total_price').text(totalPrice.toFixed(2).replace('.', ','))

      $addToCartButton.prop('disabled', true)
      if (totalTickets >= 1) {
        $addToCartButton.prop('disabled', false)
      }
    }

    $addToCartButton.on('click', () => {
      $("#page_spinner").show()
    })

    $('.increment').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')
      const availability = $(this).parent().find('.hidden_availability')
      const typologyMaxTickets = $(this).parent().find('.typology_max_tickets').val()
      const newValue = (parseInt(count.text()) + 1)
      if (availability.val() === 'true' && block === false) {
        if (typologyMaxTickets) {
          if (newValue > typologyMaxTickets) {
            return
          } else if (newValue == typologyMaxTickets) {
            $(this).parent().find('.increment').removeClass('bg-dark-gray').addClass('bg-light-gray')
            $(this).parent().find('.tooltip').attr('data-active', 'true')
          }
        }
        count.text(`${parseInt(count.text()) + 1}`)
        hiddenCount.val(count.text())

        if (count.text() === '1') {
          $(this).parent().find('.decrement').removeClass('bg-light-gray').addClass('bg-dark-gray')
          $addToCartButton.prop('disabled', false)
        }

        calculateTotals()
      }
    })

    $('.decrement').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')

      if (parseInt(count.text()) > 0) {
        count.text(`${parseInt(count.text()) - 1}`)
        hiddenCount.val(count.text())

        if (count.text() === '0') {
          $(this).removeClass('bg-dark-gray').addClass('bg-light-gray')
          $addToCartButton.prop('disabled', true)
        }

        if ($(this).parent().find('.increment').hasClass('bg-dark-gray')) {
          $(this).parent().find('.increment').removeClass('bg-dark-gray').addClass('bg-dark-gray')
          $(this).parent().find('.tooltip').attr('data-active', 'false')
        }
        if (block) {
          block = false
          $('.ticket_count').each(function () {
            const availability = $(this).parent().find('.hidden_availability')
            if (availability.val() === 'true') {
              $(this).parent().find('.increment').removeClass('bg-light-gray').addClass('bg-dark-gray')
              $(document).find('.tooltip').attr('data-active', 'false')
            }
          })
        }
        calculateTotals()
      }
    })
  }
})
