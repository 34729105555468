import { Controller } from '@hotwired/stimulus'
import * as Routes from 'routes.js.erb'

export default class extends Controller {
  connect () {
    this.element.checked = this.element.dataset.checked === 'true'
  }
  update () {
    const ref = this.element.dataset.ref
    const checked = this.element.checked
    const data = {
      insurance: JSON.stringify([{
        [ref]: checked
      }])
    }
    $('#spinner').css({ visibility: 'visible', opacity: '1' })
    const $this = this
    $.ajax({
      type: 'POST',
      url: Routes.cart_insurance_path(),
      data: data,
      success: function (response) {
        if (response.state.state === 'success') {
          $('#cart_resume').get(0).cartResume.update(response.cart)
          $('#spinner').css({ visibility: 'hidden', opacity: '0' })
          $this.element.closest('#select_all_controller').selectAllController.updateElement()
        } else {
          $this.element.checked = !$this.element.checked
        }
        $($this.element).attr('data-checked', $this.element.checked)
      }
    })
  }
}
